import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Svenska's Culinary - Contact" />
      <div className="content">
        <p>Please direct all inquiries to kristinhamill [at] earthlink [dot] net.</p>
      </div>
    </Layout>
  )
}

export default IndexPage
